<template>
    <!-- 导航 -->
    <page-head title="智慧球场"/>
    <content-bg>
        <template v-slot:content>
            <div v-for="item in smartPitchList" class="smartPitchList">
                <p class="group">{{ item.group }}</p>
                <div class="smartPitchItem">
                    <div v-for="i in item.cameras" @click="videoFn(item, i)" class="camera">
                        <img src="@i/smartPitch/SmartPitch.svg" alt="">
                        <p class="cameraName">{{ i.name }}</p>
                    </div>
                </div>

            </div>
        </template>
    </content-bg>
</template>

<script>
import {useRouter} from "vue-router";
import {getCurrentInstance, reactive, toRefs} from "vue";
import {useStore} from "vuex";

import $axios from 'axios'

export default {
    name: "index",
    setup() {

        const router = useRouter()
        const {proxy} = getCurrentInstance()
        const store = useStore()

        const state = reactive({
            smartPitchList: []
        })

        proxy.$server.getSmartPitchDevices()
            .then(res => {
                state.smartPitchList = res.data;
            })

        const videoFn = (pitch, video) => {
            router.push({
                path: '/smartPitch/camera',
                query: {
                    pitch: pitch.stream,
                    video: JSON.stringify(video)
                }
            })
        }

        return {
            ...toRefs(state),

            videoFn,
        }
    }
}
</script>

<style scoped lang="scss">
.smartPitchList {
    margin-left: 35px;

    .group {
        font-size: 28px;
        line-height: 39px;
        color: #fff;
        text-align: left;
        margin-top: 34px;
        margin-bottom: 16px;
    }

    .smartPitchItem {
        display: flex;
        flex-wrap: wrap;
        border-radius: 6px;
        overflow: hidden;

        .camera {
            width: 30%;
            margin-right: 3%;
            background-color: rgba(245, 248, 255, .1);
            box-shadow: 0px 17.9077px 17.9077px rgba(0, 50, 174, 0.08);

            img {
                width: 100%;
            }

            .cameraName {
                color: rgba(255, 255, 255, .8);
                font-size: 28px;
                font-family: PingFangMedium;
                padding-left: 24px;
                line-height: 39px;
                text-align: left;
                padding-top: 14px;
                padding-bottom: 14px;
            }
        }
    }
}
</style>